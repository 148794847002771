import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import QwertyKeypad from 'components/common/QwertyKeypad';
import passport from 'images/PassportCertification/passport.png';
import progressKo from 'images/PassportCertification/progress_ko_1.png';
import progressEn from 'images/PassportCertification/progress_en_1.png';
import progressCh from 'images/PassportCertification/progress_ch_1.png';
import progressJp from 'images/PassportCertification/progress_jp_1.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import arrowRight from 'images/common/chevron_right_white.png';

import useGetRefundInfo from 'hooks/useGetRefundInfo';
import { languageAtom, loginInfoAtom } from 'store/globalStateAtom';
import useLogging from 'hooks/useLogging';

function PassportCertification() {
  const { t } = useTranslation();
  const [lang] = useAtom(languageAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [passportNumber, setPassportNumber] = useState('');
  const navigate = useNavigate();
  const { mutate, isLoading } = useGetRefundInfo();
  const { mutate: logOnServer } = useLogging();

  const log = (data: string) => {
    logOnServer({ controlCode: loginInfo?.controlCode, data });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassportNumber(e.target.value.trim().toUpperCase());
  };
  const goBack = () => {
    navigate('/language-selection');
  };
  const goNext = () => {
    log(
      `키오스크 여권 조회 :: - 키오스크 이름: ${loginInfo?.name}, - passportNumber: ${passportNumber}`,
    );
    mutate({ passportNumber, kioskId: loginInfo?.kioskId });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/promotion');
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout isLoading={isLoading} isSetting>
      <img
        src={
          lang === 'en'
            ? progressEn
            : lang === 'jp'
              ? progressJp
              : lang === 'ch'
                ? progressCh
                : progressKo
        }
        height={90}
        width={960}
        alt='progress'
        className='mb-16 w-full'
      />
      <div className='w-full rounded-20 bg-mono-05 p-11'>
        <img
          src={passport}
          height={410}
          width={600}
          alt='passport'
          className='mx-auto'
        />
      </div>
      <h2 className='text-head-01 text-mono-80 font-bold mt-16'>
        {t('enter_passport')}
      </h2>
      <input
        className='py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16 outline outline-4 outline-blue-60'
        placeholder='Passport NO.'
        onChange={onChange}
        value={passportNumber}
      />
      <div className='flex justify-between'>
        <button
          onClick={goBack}
          className='text-subHead-01 flex items-center bg-white py-6 px-28 rounded-100 text-mono-80 font-semibold mt-36 shadow-default'
        >
          <img src={arrowLeft} width={36} height={36} alt='arrow_left' />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          disabled={!passportNumber}
          className='text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-28 rounded-100 font-semibold mt-36 shadow-default'
        >
          {t('next_step')}
          <img src={arrowRight} width={36} height={36} alt='arrow_right' />
        </button>
      </div>
      <QwertyKeypad setInput={setPassportNumber} containerStyle='mt-4' />
    </Layout>
  );
}

export default PassportCertification;
