import { atom } from 'jotai';

import { BillType, UserInfo } from 'api';

export type Language = 'jp' | 'ch' | 'en' | 'ko';
export type ModalInfo = {
  icon?: 'ALERT' | 'CHECK' | 'ERROR' | 'RESET';
  title?: string;
  description?: string;
  pointColorText?: string;
  btnText?: string; //(default: "확인")
  btnClass?: string;
  btnCallback?: () => void; //모달 닫기 전에 실행할 함수
  preventDefault?: boolean; //기본 닫기 동작 막기 (default: false)
  imageUrl?: string;
  centerImageUrl?: string;
  centerImageDescription?: string;
  subBtnText?: string;
  subBtnClass?: string;
  subBtnCallback?: () => void;
  subBtnPreventDefault?: boolean; //기본 닫기 동작 막기 (default: false)
  btnDisabled?: boolean;
  videoUrl?: string;
};
export type KioskStatus = {
  hp1TotalCount: number;
  bd1TotalCount: number;
  bd2TotalCount: number;
  bd3TotalCount: number;
  hp1Error: boolean;
  bd1Error: boolean;
  bd2Error: boolean;
  bd3Error: boolean;
  bd1ErrorCode: number;
  bd2ErrorCode: number;
  bd3ErrorCode: number;
};
export type CountInfo = {
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
  bd3Count: number;
};

export type RefundInfo = {
  refundInfoList: Array<{
    name: string;
    refundIndex: number;
    createdDate: string;
    price: string;
    refund: string;
    serviceType: string;
  }>;
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
  bd3Count: number;
};

export type AdminLoginInfo = {
  isManager: boolean | null;
  controlCode: string | null;
};

const getStorageValue = <T>(key: string, defaultValue: T) => {
  const storageValue = localStorage.getItem(key);
  return storageValue ? JSON.parse(storageValue) : defaultValue;
};

//읽기 전용
const getLanguageAtom = atom(getStorageValue('language', undefined));
const getLoginInfoAtom = atom(getStorageValue('loginInfo', undefined));
const getKioskStatusAtom = atom(
  getStorageValue('kioskStatus', {
    hp1TotalCount: 0,
    bd1TotalCount: 0,
    bd2TotalCount: 0,
    hp1Error: false,
    bd1Error: false,
    bd2Error: false,
    bd1ErrorCode: null,
    bd2ErrorCode: null,
  }),
);
const getMoneyNotWithdrawn = atom(
  getStorageValue('moneyNotWithdrawn', {
    hp1Count: 0,
    bd1Count: 0,
    bd2count: 0,
  }),
);
const getRefundInfoAtom = atom(getStorageValue('refundInfo', undefined));
const getAdminLoginInfoAtom = atom(
  getStorageValue('adminLoginInfo', { manager: null, controlCode: null }),
);
const getBillTypeAtom = atom(
  getStorageValue('billType', { bd1: null, bd2: null, bd3: null, hp1: null }),
);

//읽기 쓰기 전용
export const modalInfoAtom = atom<null | ModalInfo>(null); //스토리지 저장 안함
export const languageAtom = atom(
  (get) => get(getLanguageAtom),
  (get, set, newLanguage: Language | undefined) => {
    set(getLanguageAtom, newLanguage);
    localStorage.setItem('language', JSON.stringify(newLanguage));
  },
);

export const loginInfoAtom = atom(
  (get) => get(getLoginInfoAtom),
  (get, set, newLoginInfo: UserInfo | undefined) => {
    set(getLoginInfoAtom, newLoginInfo);
    localStorage.setItem('loginInfo', JSON.stringify(newLoginInfo));
  },
);

export const kioskStatusAtom = atom(
  (get) => get(getKioskStatusAtom),
  (get, set, newKioskStatus: KioskStatus) => {
    set(getKioskStatusAtom, newKioskStatus);
    localStorage.setItem('kioskStatus', JSON.stringify(newKioskStatus));
  },
);
export const moneyNotWithdrawnAtom = atom(
  (get) => get(getMoneyNotWithdrawn),
  (get, set, newMoneyNotWithdrawn: CountInfo) => {
    set(getMoneyNotWithdrawn, newMoneyNotWithdrawn);
    localStorage.setItem(
      'moneyNotWithdrawn',
      JSON.stringify(newMoneyNotWithdrawn),
    );
  },
);
export const refundInfoAtom = atom(
  (get) => get(getRefundInfoAtom),
  (get, set, newRefundInfo: RefundInfo) => {
    set(getRefundInfoAtom, newRefundInfo);
    localStorage.setItem('refundInfo', JSON.stringify(newRefundInfo));
  },
);
export const adminLoginInfoAtom = atom(
  (get) => get(getAdminLoginInfoAtom),
  (get, set, newAdminLoginInfo: AdminLoginInfo) => {
    set(getAdminLoginInfoAtom, newAdminLoginInfo);
    localStorage.setItem('adminLoginInfo', JSON.stringify(newAdminLoginInfo));
  },
);
export const billTypeAtom = atom(
  (get) => get(getBillTypeAtom),
  (get, set, newBillType: BillType) => {
    set(getBillTypeAtom, newBillType);
    localStorage.setItem('billType', JSON.stringify(newBillType));
  },
);
