import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import progressKo from 'images/RefundComplete/progress_ko_3.png';
import progressEn from 'images/RefundComplete/progress_en_3.png';
import progressJp from 'images/RefundComplete/progress_jp_3.png';
import progressCh from 'images/RefundComplete/progress_ch_3.png';
import kiosk from 'images/RefundComplete/kiosk.gif';
import { languageAtom } from 'store/globalStateAtom';

function RefundComplete() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lang] = useAtom(languageAtom);

  const goNext = () => {
    navigate('/promotion');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      goNext();
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => document.removeEventListener('touchmove', handleTouchMove);
  }, []);

  return (
    <Layout>
      <img
        src={
          lang === 'en'
            ? progressEn
            : lang === 'jp'
              ? progressJp
              : lang === 'ch'
                ? progressCh
                : progressKo
        }
        height={90}
        width={960}
        alt='progress'
        className='mb-16 w-full'
      />
      <img
        src={kiosk}
        height={500}
        width={960}
        alt='kiosk'
        className='my-16 w-full'
      />
      <h3 className='text-head-01 font-bold'>{t('complete_refund')}</h3>
      <button
        onClick={goNext}
        className='block text-head-02 disabled:bg-blue-20 bg-blue-100 text-white py-6 px-28 w-2/3 mx-auto rounded-100 font-semibold mt-64 shadow-default active:bg-blue-150'
      >
        {t('complete_check')}
      </button>
    </Layout>
  );
}

export default RefundComplete;
