import React, { Dispatch, SetStateAction, useState } from 'react';
import { useAtom } from 'jotai';

import { ActiveInput, BillCalculateType } from 'pages/CashCharging';
import {
  KioskStatus,
  adminLoginInfoAtom,
  billTypeAtom,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';
import { formatBillType, calculateCount } from 'utils';

import reset from 'images/common/reset_black.png';
import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';

type Props = {
  type?: string;
  billUnit: number;
  targetCount?: number;
  currentCount?: number;
  value: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<ActiveInput>>;
  calculateType?: BillCalculateType;
  setCalculateType?: Dispatch<SetStateAction<BillCalculateType>>;
  disabled?: boolean;
};
function ChargingItem({
  type,
  billUnit,
  targetCount,
  currentCount,
  value,
  active,
  setActive,
  calculateType,
  setCalculateType,
  disabled,
}: Props) {
  const [billType] = useAtom(billTypeAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [adminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);

  const { bd1TotalCount, bd2TotalCount, bd3TotalCount, hp1TotalCount } =
    kioskStatus as KioskStatus;

  const { mutate } = useUpdateKioskStatus();

  const onCalculateType = (calculateType: string) => {
    if (type && setCalculateType) {
      setCalculateType((prev) => ({ ...prev, [type]: calculateType }));
    }
  };

  const onReset = () => {
    const newKioskStatus = {
      ...kioskStatus,
      hp1TotalCount: hp1TotalCount,
      bd1TotalCount: bd1TotalCount,
      bd2TotalCount: bd2TotalCount,
      bd3TotalCount: bd3TotalCount,
    };
    switch (type) {
      case 'hp1':
        newKioskStatus.hp1TotalCount = 0;
        break;
      case 'bd1':
        newKioskStatus.bd1TotalCount = 0;
        break;
      case 'bd2':
        newKioskStatus.bd2TotalCount = 0;
        break;
      case 'bd3':
        newKioskStatus.bd3TotalCount = 0;
        break;
      default:
        break;
    }
    mutate({
      type: 'DEPOSIT',
      controlCode: loginInfo.controlCode,
      managerCode: adminLoginInfo.controlCode,
      ...newKioskStatus,
    });
    setKioskStatus(newKioskStatus);
  };

  const openResetModal = (typeName: string) => {
    setModalInfo({
      icon: 'ALERT',
      title: `${typeName} 잔량 초기화`,
      description: `잔량을 초기화 하시겠습니까?`,
      btnText: '닫기',
      btnClass:
        'bg-mono-10 text-mono-80 active:bg-mono-20 w-[240px] text-white', // 왼쪽 버튼
      btnCallback: () => {},
      subBtnText: '확인',
      subBtnClass:
        'bg-blue-100 text-white active:bg-blue-150 flex justify-center items-center gap-2 w-[582px]', // 오른쪽 버튼
      subBtnCallback: () => {
        onReset();
      },
    });
  };

  return (
    <div
      className={`flex flex-col gap-5 point ${
        disabled ? 'pointer-events-none opacity-40' : ''
      }`}
    >
      <div className='flex gap-5'>
        <div className='bg-mono-05 py-8 px-12 rounded-20 text-body-01 text-mono-65 font-semibold flex justify-between  flex-1'>
          <div className='flex gap-3'>
            <button
              className='bg-mono-01 py-2 px-2 rounded-100'
              onClick={() =>
                type &&
                openResetModal(
                  `${formatBillType(billType[type])}(${type.toUpperCase()})`,
                )
              }
            >
              <img src={reset} height={24} width={24} alt='reset' />
            </button>
            <p>
              {type
                ? `${formatBillType(billType[type])}(${type.toUpperCase()})`
                : `${formatBillType(billUnit)}(미사용)`}
            </p>
          </div>
          <p>
            <span className={`${value && 'text-blue-100'}`}>
              {type
                ? `${
                    calculateType?.[type as keyof typeof calculateType] === '+'
                      ? Number(currentCount) + Number(value) || 0
                      : Number(currentCount) - Number(value) || 0
                  }`
                : '-'}
            </span>
            <span>{type ? `/${targetCount}` : ''}</span>
          </p>
        </div>
        <div className='flex gap-5'>
          <button
            className={`text-body-01 group-:text-blue-100 bg-mono-05 w-[100px] rounded-20 ${
              type &&
              calculateType?.[type as keyof typeof calculateType] === '+'
                ? 'outline outline-4 outline-blue-60'
                : ''
            }`}
            onClick={() => onCalculateType('+')}
          >
            +
          </button>
          <button
            className={`text-body-01 group-:text-blue-100 bg-mono-05 w-[100px] rounded-20 ${
              type &&
              calculateType?.[type as keyof typeof calculateType] === '-'
                ? 'outline outline-4 outline-blue-60'
                : ''
            }`}
            onClick={() => onCalculateType('-')}
          >
            -
          </button>
        </div>
        <div>
          <input
            name={type + 'Count'}
            value={value}
            className={`py-8 px-8 text-center rounded-20 placeholder:text-body-01 text-body-01 w-[194px] outline-none ${
              active ? 'outline outline-4 outline-blue-60' : ''
            } focus:text-mono-100 text-blue-100 before:content-['+']`}
            placeholder={type === 'hp1' ? '00' : '000'}
            onClick={() => setActive((type + 'Count') as ActiveInput)}
          />
        </div>
      </div>
    </div>
  );
}

export default ChargingItem;
