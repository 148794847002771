import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

export type Error = {
  value: number;
  reason: string;
  code: string;
  message: string;
  requestURI: string;
  requestMethod: string;
};
//서버 주소
export const SERVER_PROD = 'https://kiosk.ktaxpay.com';
export const SERVER_TEST = 'https://kiosktest.ktaxpay.com';
export const SERVER_LOCAL_TEST = 'http://123.142.246.2:50927'; // 로컬 테스트

export const SERVER_URL = SERVER_PROD;

//요청 헤더
export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: SERVER_URL,
  responseType: 'json',
};

//성공 응답시 전처리
const onFullfilled = (response: AxiosResponse<any>) => {
  return response;
};

//에러 발생시 전처리
const onRejected = async (error: AxiosError<any>) => {
  return Promise.reject(error);
};

//초기화
const initialize = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.response.use(onFullfilled, onRejected);

  return axiosInstance;
};
const axiosInstance = initialize(axiosRequestConfiguration);

/**
 * 응답 데이터, Payload, Params, Url의 타입을 전달받아 적용하기 위한
 * API Request Axios Wrapper with Generics
 * @returns Response Data<ResponseType>
 */
const getAxios = async <ResponseType, ParamType>(
  url: string,
  queryParams?: ParamType,
  config?: any,
) =>
  axiosInstance.get<ResponseType>(url, {
    ...(queryParams && { params: queryParams }),
    ...config,
  });

const postAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.post<ResponseType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const putAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.put<ResponseType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const patchAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body?: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.patch<ResponseType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const deleteAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body?: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.delete<ResponseType>(url, {
    ...(body && { data: body }),
    ...(queryParams && { params: queryParams }),
  });

export { getAxios, postAxios, putAxios, patchAxios, deleteAxios };
